import { useEffect, useRef, useState } from "react";
import bridge from "@vkontakte/vk-bridge";
import { Logo } from "../Logo";
import MainButton from "../MainButton";
import "./Home.css";
import { Svg } from "../Svg";
import bg from "./bg.png";
import rostec from "./rostec.svg";
import { LogoEdu } from "../LogoEdu";

const Home = () => {
  const [videoId, setVideoId] = useState("");
  const [value, setValue] = useState("");
  const [error, setError] = useState(false);
  const [isOk, setIsOk] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [project, setProject] = useState("");
  const dfoRef = useRef(null);
  const [isImageLoad, setIsImageLoad] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(window.location.hash.substring(1));
    setVideoId(params.get("video_id"));
    setProject(params.get("project"));
  }, []);

  useEffect(() => {
    console.log(dfoRef.current);
    if (dfoRef.current) {
      dfoRef.current.style.paddingTop = "120px";
    }
  }, [dfoRef.current]);

  useEffect(() => {
    console.log(videoId);
    if (project === "vkeducation" || project === "vkrusedu") {
      document.body.style.backgroundColor = "#07f";
    } else if (project === "dfo-ai") {
      document.body.style.background = `url(${bg}), lightgray -299px 0px / 227.467% 100.353% no-repeat`;
      document.body.style.backgroundSize = "cover";
      document.body.style.backgroundRepeat = "no-repeat";
      document.body.style.backgroundAttachment = "fixed";
    } else if (project === "vkclips") {
      document.body.style.backgroundColor = "#000";
    }
    if (videoId) {
      setValue(videoId);
    }
  }, [videoId, project]);

  function encodeUrlToBase64(url) {
    return window.btoa(url);
  }

  function downloadPhoto() {
    const link = document.createElement("a");
    link.href = `https://vk-edu-ai.hb.vkcloud-storage.ru/vkrusedu/${videoId}.png`;
    link.download = "imageName.jpg"; // Замените imageName на имя вашего изображения
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const uploadPhoto = async () => {
    setIsLoading(true);

    try {
      const fetchPhoto = async (url) => {
        const response = await fetch(url);
        console.log(response);
        return response.blob();
      };

      const authData = await bridge.send("VKWebAppGetAuthToken", {
        app_id: 51770079,
        scope: "photos,wall",
      });
      console.log("authData: ", authData);
      const accessToken = authData.access_token;

      const serverData = await bridge.send("VKWebAppCallAPIMethod", {
        method: "photos.getWallUploadServer",
        params: {
          access_token: accessToken,
          v: "5.131",
        },
      });
      console.log("serverData: ", serverData);
      const uploadUrl = serverData.response.upload_url;

      const base64String = encodeUrlToBase64(uploadUrl);

      // const photoBlob = await fetchPhoto(
      //   `https://vkshare.ru/media-cloud/dfo-ai/${videoId}`
      // );
      // const formData = new FormData();
      // formData.append("file", photoBlob, "DFO.png");
      const preUpload = await fetch(
        project === "rostec"
          ? `https://vkshare.ru/api/UploadFileToUrl?media_url=${videoId}&upload_url=${base64String}`
          : project === "vkrusedu"
          ? `https://vkshare.ru/api/UploadFileToUrl?media_url=https://vk-edu-ai.hb.vkcloud-storage.ru/vkrusedu/${videoId}.png&upload_url=${base64String}`
          : `https://vkshare.ru/api/UploadFileToUrl?media_url=https://vkshare.ru/media-cloud/dfo-ai/${videoId}&upload_url=${base64String}`,
        {
          method: "GET",
        }
      );

      console.log("preUpload: ", preUpload);

      const preUploadData = await preUpload.json();
      console.log("preUploadData: ", preUploadData);

      // const uploadResponse = await fetch(uploadUrl, {
      //   method: "POST",
      //   body: formData,
      // });
      // console.log(uploadResponse);
      // const uploadResult = await uploadResponse.json();

      const saveData = await bridge.send("VKWebAppCallAPIMethod", {
        method: "photos.saveWallPhoto",
        params: {
          photo: preUploadData.photo,
          server: preUploadData.server,
          hash: preUploadData.hash,
          access_token: accessToken,
          v: "5.131",
        },
      });
      console.log("saveData: ", saveData);

      function getRandomMessage(arr) {
        return arr[Math.floor(Math.random() * arr.length)];
      }

      const firstMessage =
        "Мой аватар от @vkeducation 💫\n" +
        "Теперь я знаю, какая IT-профессия мне подходит!\n";
      const secondMessage =
        "Нейросеть от @vkeducation видит меня в таком образе 👀\n";
      const messages = [firstMessage, secondMessage];

      const photo = saveData.response[0];
      bridge
        .send("VKWebAppShowWallPostBox", {
          message:
            project === "vkrusedu"
              ? getRandomMessage(messages)
              : "Так твою профессию на Дальнем Востоке определяет нейросеть.",
          owner_id: photo.owner_id,
          attachments: `photo${photo.owner_id}_${photo.id}`,
          access_token: accessToken,
        })
        .then((data) => {
          if (data.post_id) {
            console.log("The post is on your wall");
            setIsOk(true);
          }
        })
        .catch((error) => {
          // Ошибка
          console.log(error);
          setIsLoading(false);
        });
    } finally {
      setIsLoading(false);
    }
  };

  const uploadVideo = () => {
    setIsLoading(true);

    const fetchVideo = async (url) => {
      const response = await fetch(url);
      return response.blob();
    };

    bridge
      .send("VKWebAppGetAuthToken", {
        app_id: 51770079,
        scope: "video,wall",
      })
      .then(async (data) => {
        const accessToken = data.access_token;

        const videoBlob = await fetchVideo(
          project === "vkclips"
            ? `https://vkshare.ru/media-cloud/vkclips/${videoId}`
            : project === "vkeducation"
            ? `https://vkshare.ru/media-cloud/videos/${videoId}`
            : `https://vkshare.ru/media-cloud/vkrusedu/${videoId}`
        );

        const formData = new FormData();
        formData.append(
          "video_file",
          videoBlob,
          project === "vkclips"
            ? "VK Клипы #VKхВыставкаРоссия.mp4"
            : "VK Образование - Моя профессия в IT.mp4"
        );

        bridge
          .send("VKWebAppCallAPIMethod", {
            method: "video.save",
            params: {
              name:
                project === "vkclips"
                  ? "VK Клипы #VKхВыставкаРоссия"
                  : "VK Образование - Моя профессия в IT",
              description: "",
              repeat: 1,
              wallpost: 1,
              // group_id: 222981788,
              access_token: accessToken,
              v: "5.131",
            },
          })
          .then((data) => {
            const uploadUrl = data.response.upload_url;

            fetch(uploadUrl, {
              method: "POST",
              body: formData,
            })
              .catch((e) => {
                setError(true);
              })
              .finally(() => {
                setIsOk(true);
                setIsLoading(false);
              });
          });
      });
  };

  if (project === "rostec") {
    return (
      <div
        className="vkuiPanel__in vkuiInternalPanel__in"
        style={{ height: "100%", backgroundColor: "#fff" }}
      >
        {!isOk && (
          <>
            {" "}
            <div className="vkuiPanelHeader vkuiInternalPanelHeader vkuiPanelHeader--vkcom vkuiInternalPanelHeader--vkcom vkuiPanelHeader--vis vkuiInternalPanelHeader--vis vkuiPanelHeader--sep vkuiInternalPanelHeader--sep vkuiPanelHeader--no-after vkuiPanelHeader--fixed vkuiPanelHeader--sizeX-none">
              <div className="vkuiFixedLayout vkuiFixedLayout--vertical-top vkuiPanelHeader__fixed vkuiInternalPanelHeader__fixed">
                <div
                  className="vkuiPanelHeader__in"
                  style={{ justifyContent: "center" }}
                >
                  <div className="vkuiPanelHeader__content">
                    <div className="vkuiTypography vkuiTypography--normalize vkuiTypography--weight-2 vkuiText vkuiText--sizeY-none">
                      <h3
                        className="vkuiTypography vkuiTypography--normalize vkuiTitle--level-3"
                        style={{ margin: 0 }}
                      >
                        Новая запись
                      </h3>
                      <span
                        className="vkuiTypography vkuiTypography--normalize vkuiFootnote"
                        style={{ color: "var(--vkui--color_text_tertiary)" }}
                      >
                        Выставка-форум «Россия»
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="vkuiDiv CreatePostForm-TagsList"
              style={{ backgroundColor: "#fff" }}
            >
              <img src={rostec} alt="logo" width={160}></img>
            </div>
            <div
              className="vkuiBaseGallery vkuiBaseGallery--align-center rostec-image"
              style={{ backgroundColor: "#fff", height: "330px" }}
            >
              <div
                className="vkuiBaseGallery__viewport"
                style={{
                  width:
                    "calc(100% - var(--vkui--size_base_padding_horizontal--regular) * 2)",
                  backgroundColor: "#f2f3f5",
                  borderRadius: "16px",
                  overflow: "hidden",
                }}
              >
                {!isImageLoad && (
                  <div className="spinner">
                    <Svg.Spinner />
                  </div>
                )}
                <img
                  src={videoId}
                  onLoad={() => setIsImageLoad(true)}
                  onError={() =>
                    alert(
                      "Файла не существует или он еще не загружен на наш сервер. Пожалуйста, попробуйте обновить страницу через 1 минуту."
                    )
                  }
                  style={{
                    width: "100%",
                    height: "100%",
                    visibility: isImageLoad ? "visible" : "hidden",
                    objectFit: "contain",
                    zIndex: 1,
                  }}
                ></img>
                <div
                  className="blur"
                  style={{
                    background: `url(${videoId})`,
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    top: 0,
                    zIndex: 0,
                    filter: "blur(30px)",
                  }}
                ></div>
              </div>
            </div>
            <div
              className="PostFooterSkeleton"
              style={{ backgroundColor: "#fff" }}
            >
              <div className="PostFooterSkeleton-Left">
                <span aria-live="polite" aria-busy="false">
                  <span className="react-loading-skeleton">&zwnj;</span>
                  <br />
                </span>
                <span aria-live="polite" aria-busy="false">
                  <span className="react-loading-skeleton">&zwnj;</span>
                  <br />
                </span>
                <span aria-live="polite" aria-busy="false">
                  <span className="react-loading-skeleton">&zwnj;</span>
                  <br />
                </span>
              </div>
            </div>
            <div className="StickyBottomLayout StickyBottomLayout_exiting">
              <div className="vkuiDiv" style={{ backgroundColor: "#fff" }}>
                {error && <span>Что-то пошло не так...</span>}
                {isLoading && (
                  <button className="vkuiButton vkuiButton--size-l vkuiButton--mode-primary vkuiButton--appearance-accent vkuiButton--align-center vkuiButton--sizeY-none vkuiButton--stretched vkuiTappable vkuiInternalTappable vkuiTappable--sizeX-none vkuiTappable--hasHover">
                    <span className="vkuiButton__in">
                      <span className="vkuiButton__content">Загрузка...</span>
                    </span>
                  </button>
                )}
                {!isLoading && (
                  <button
                    onClick={uploadPhoto}
                    className="vkuiButton vkuiButton--size-l vkuiButton--mode-primary vkuiButton--appearance-accent vkuiButton--align-center vkuiButton--sizeY-none vkuiButton--stretched vkuiTappable vkuiInternalTappable vkuiTappable--sizeX-none vkuiTappable--hasHover"
                  >
                    <span className="vkuiButton__in">
                      <span className="vkuiButton__content">Опубликовать</span>
                    </span>
                  </button>
                )}
              </div>
            </div>
          </>
        )}
        {isOk && (
          <div
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              background: "#fff",
            }}
          >
            <h1>Запись была опубликована в вашем профиле</h1>
          </div>
        )}
      </div>
    );
  }

  if (project === "dfo-ai") {
    return (
      <>
        <div
          className="dfoWrapper"
          ref={dfoRef}
          style={{
            position: "relative",
            maxWidth: "280px",
            margin: "0 auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h1 className="eastTitle">Дальний Восток - фото твоей профессии</h1>
          <p className="eastText">
            Дорогой путешественник, спасибо что посетил наш стенд. В приложении
            образ того, как бы ты выглядела если бы выбрал профессию работы на
            дальнем востоке.
          </p>
          <img
            onError={() =>
              alert(
                "Файла не существует или он еще не загружен на наш сервер. Пожалуйста, попробуйте обновить страницу через 1 минуту."
              )
            }
            src={`https://vkshare.ru/media-cloud/dfo-ai/${videoId}`}
            style={{
              height: "552px",
              borderRadius: "20px",
              marginBottom: "20px",
              marginTop: "40px",
            }}
          ></img>
          {error && <span>Что-то пошло не так...</span>}
          {isOk && <span>Видео было успешно опубликовано</span>}
          {isLoading && <button className="eastBtn">Загрузка</button>}
          {!isLoading && (
            <button className="eastBtn" onClick={uploadPhoto}>
              Опубликовать
            </button>
          )}
          <Svg.EastBack />
          <Svg.Mountains />
        </div>
        <Svg.EastLogo />
      </>
    );
  } else if (project === "vkeducation") {
    return (
      <div
        style={{
          paddingTop: "80px",
          paddingBottom: "80px",
          maxWidth: "280px",
          margin: "0 auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Logo />
        <Svg.Decor />
        <h1 className="title">Построй свой путь в IT и digital</h1>
        <ul className="list">
          <li className="list-item">
            Выбирай один из множества бесплатных образовательных IT- и
            digital-проектов.
          </li>
          <li className="list-item">
            Расти профессионально и получай прикладные знания.
          </li>
          <li className="list-item">Получи шанс стать частью нашей команды.</li>
        </ul>
        <a
          className="vkEd"
          href="https://vk.cc/cs2Jhd"
          style={{ textDecoration: "none" }}
        >
          <p>VK Education</p>
        </a>
        <video
          src={
            project === "vkeducation"
              ? `https://vkshare.ru/media-cloud/videos/${videoId}`
              : `https://vkshare.ru/media-cloud/vkrusedu/${videoId}`
          }
          autoPlay
          loop
          muted
          style={{
            width: "80%",
            borderRadius: "20px",
            marginBottom: "20px",
          }}
        ></video>
        {error && <span>Что-то пошло не так...</span>}
        {isOk && <span>Видео было успешно опубликовано</span>}
        {isLoading && <MainButton title={"Загрузка"} />}
        {!isLoading && (
          <div className="buttons">
            <MainButton title={"Опубликовать"} onClick={uploadVideo} />
          </div>
        )}
      </div>
    );
  } else if (project === "vkrusedu") {
    return (
      <div
        style={{
          padding: "112px 0 40px",
          maxWidth: "327px",
          margin: "0 auto",
        }}
        className="education"
      >
        <LogoEdu />
        <Svg.DecorEdu />
        <h1 className="title">
          Построй свой <br /> путь в IT и digital
        </h1>
        <ul className="list">
          <li className="list-item">
            Выбирай один из множества бесплатных образовательных <br />
            IT- и digital-проектов.
          </li>
          <li className="list-item">
            Расти профессионально и получай прикладные знания.
          </li>
          <li className="list-item">Получи шанс стать частью нашей команды.</li>
        </ul>
        <a className="education-btn-outline" href="https://vk.cc/cs2Jhd">
          VK Education
        </a>
        <img
          onError={() =>
            alert(
              "Файла не существует или он еще не загружен на наш сервер. Пожалуйста, попробуйте обновить страницу через 1 минуту."
            )
          }
          src={`https://vk-edu-ai.hb.vkcloud-storage.ru/vkrusedu/${videoId}.png`}
          style={{
            width: "327px",
            height: "420px",
            objectFit: "cover",
            borderRadius: "20px",
            margin: "32px 0",
          }}
        ></img>
        {error && <span>Что-то пошло не так...</span>}
        {isOk && <span>Ваш образ будет опубликован</span>}
        {isLoading && <button className="education-btn">Загрузка</button>}
        {!isLoading && (
          <div className="buttons">
            <button className="education-btn" onClick={downloadPhoto}>
              Скачать
            </button>
            <button className="education-btn" onClick={uploadPhoto}>
              Опубликовать
            </button>
          </div>
        )}
      </div>
    );
  } else if (project === "vkclips") {
    return (
      <>
        <div
          style={{
            paddingTop: "80px",
            paddingBottom: "80px",
            maxWidth: "280px",
            margin: "0 auto",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            position: "absolute",
            width: "100%",
            transform: "translate(-50%)",
            zIndex: 99,
            left: "50%",
          }}
        >
          <Svg.VkClipLogo />
          <h1 className="clipTitle">на выставке «Россия»</h1>
          <p className="textTitle">
            Сняли прикольное видео в нашей зоне? Скачайте его из этой галереи и
            опубликуйте в сети с хештегом
          </p>
          <div className="blockClip">#VKхВыставкаРоссия</div>
          <video
            src={`https://vkshare.ru/media-cloud/vkclips/${videoId}`}
            autoPlay
            loop
            muted
            style={{
              width: "100%",
              borderRadius: "20px",
              marginBottom: "20px",
              marginTop: "30px",
            }}
          ></video>
          {error && <span>Что-то пошло не так...</span>}
          {isOk && <span>Видео было успешно опубликовано</span>}
          {isLoading && <button className="clipBtn">Загрузка...</button>}
          {!isLoading && (
            <button className="clipBtn" onClick={uploadVideo}>
              Опубликовать
            </button>
          )}
        </div>
        <div>
          <Svg.VkClipDecor />
        </div>
      </>
    );
  } else {
    console.log("else");
    return (
      <div className="nullProject">
        <p>
          Привет!
          <br />
          Загружаемый контент проходит модерацию социальной сети,
          <br />
          это займет совсем немного времени, попробуй выгрузить контент еще раз!
        </p>
      </div>
    );
  }
};

export default Home;
